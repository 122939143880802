'use strict';
app.factory('promptWebcamService', function ($rootScope, $http, $ocLazyLoad, errorHandlerService, broadcastService) {
    const VIDEO_OUTPUT_WIDTH = 480;
    const VIDEO_OUTPUT_HEIGHT = 480;
    const DEFAULT_OUTPUT_RATIO = 1; //Note: currently only support Portraits
    const DIRECTORY_SCRIPT = "scripts/face/";
    const SUPPORTED_CAPTURE_TYPES = ["PROFILE", "BIOMETRICS"];
    const MSG_CAMERA_PERMISSION_REQUIRED = broadcastService.getMsg(1395).MsgBody;
    const MSG_NO_WEBCAM_DETECTED = broadcastService.getMsg(1396).MsgBody;
    let cameraMediapipe;
    let handleAfterCaptureFace; //why put here? somehow it will stack if put inside displayCustomMessageWithWebcam
    let enableJSFaceDetect;

    //Why Singleton? From observation, if every time init a new one, the previous OnFrame method still there, and STACK!
    //Now, every subsequent instantiated only overwrites the 'OnFrame'
    class FaceDetectionJS {
        constructor(_onResults) {
            if (FaceDetectionJS._instance) {
                FaceDetectionJS._instance.fd.onResults(_onResults);
                return FaceDetectionJS._instance.fd;
            }
            FaceDetectionJS._instance = this;

            this.fd = new FaceDetection({ locateFile: (file) => `${DIRECTORY_SCRIPT}${file}` });
            this.fd.reset();
            this.fd.setOptions({
                modelSelection: 1,
                model: "short",
                minDetectionConfidence: 0.9
            });
            this.fd.onResults(_onResults);
            return this.fd;
        }
    }

    class CameraJS {
        constructor(_videoWebcam, _jsFaceDetection) {
            const temp = new Camera(_videoWebcam, {
                onFrame: async () => { await _jsFaceDetection.send({ image: _videoWebcam }); },
                width: VIDEO_OUTPUT_WIDTH,
                height: VIDEO_OUTPUT_HEIGHT
            });
            temp.video = _videoWebcam;
            return temp;
        }
    }

    class CameraJSNoFaceDet {
        constructor(_videoWebcam, _onFrame, _width = VIDEO_OUTPUT_WIDTH, _height = VIDEO_OUTPUT_HEIGHT) {
            return new Camera(_videoWebcam, {
                onFrame: _onFrame,
                width: _width,
                height: _height
            });
        }
    }

    const displayCustomMessageWithWebcam = async function ({ Title, CaptureType, RatioOutput, ParentScope, ImgFaceBase64_ClientToServer, EnableJSFaceDetect, OnImageCaptured, OnImageDeleted }) {
        //Parameters:
        //- Title: Title of the prompt
        //- RatioOutput: Affect the final size of the image saved (crop from existing 480x480)
        //- CaptureType: "PROFILE" -> save to Azure blob, otherwise, verify face through Azure
        //- ImgFaceBase64_ClientToServer: Default display on webcam (for face biometrics)
        ParentScope.winPromptWebcamProp = {
            LabelBtnToggleWebcam: "Turn On Webcam", LabelRegister: "Capture", Message: "",
            WebcamStatusOk: false, StartCapture: false, IsSuccess: false, IsFail: false, ImgFaceBase64_ClientToServer,
            CaptureType,
        };
        ParentScope.winPromptWebcamMethod = {};
        ParentScope.winPromptWebcam.setOptions({ width: 800, height: 650, resizable: false });
        ParentScope.winPromptWebcam.title(Title);
        ParentScope.winPromptWebcam.center().open();
        ParentScope.winPromptWebcam.bind("close", () => { turnOffCamera(); })
        ParentScope.winPromptWebcamVisible = true;

        let isWebcamOn = false;
        let videoWebcam;
        let canvaOfWebcamFaceOutput;
        let canvaContextOfWebcamFaceOutput;
        const outputWidth = RatioOutput ? RatioOutput * VIDEO_OUTPUT_HEIGHT : DEFAULT_OUTPUT_RATIO * VIDEO_OUTPUT_HEIGHT;
        const enableJSFaceDetection = Boolean(EnableJSFaceDetect);

        const initWebcamElements = () => {
            $(`#webcam-continue-${ParentScope.strUniqueID}`).text("Continue").prop("disabled", false);
            $(`#webcam-delete-image-${ParentScope.strUniqueID}`).prop("disabled", false);
            videoWebcam = document.getElementById(`video-webcam2-${ParentScope.strUniqueID}`);
            canvaOfWebcamFaceOutput = document.getElementById(`video-webcam-out2-${ParentScope.strUniqueID}`);
            canvaOfWebcamFaceOutput.width = outputWidth;
            canvaContextOfWebcamFaceOutput = canvaOfWebcamFaceOutput.getContext('2d');
            displayWordInCanvaOfWebcam("Webcam");

            handleAfterCaptureFace = ParentScope.winPromptWebcamProp.CaptureType === "PROFILE" ? captureForProfileImage :
                ParentScope.winPromptWebcamProp.CaptureType === "BIOMETRICS" ? uploadCanvaOfWebcamFaceOutput : captureForProfileImage;

            console.assert(RatioOutput <= DEFAULT_OUTPUT_RATIO, "Error: Supported ratio output is <= 1 (Portrait)");
            console.assert(SUPPORTED_CAPTURE_TYPES.includes(ParentScope.winPromptWebcamProp.CaptureType), `Error: Only support Capture types ${SUPPORTED_CAPTURE_TYPES.join(", ")}`);
        }

        const displayWordInCanvaOfWebcam = (_word) => {
            canvaContextOfWebcamFaceOutput.clearRect(0, 0, canvaOfWebcamFaceOutput.width, canvaOfWebcamFaceOutput.height);
            canvaContextOfWebcamFaceOutput.font = "88px Calibri";
            canvaContextOfWebcamFaceOutput.fillStyle = "#d0d0d0";
            canvaContextOfWebcamFaceOutput.textAlign = "center";
            canvaContextOfWebcamFaceOutput.fillText(_word, canvaOfWebcamFaceOutput.width / 2, canvaOfWebcamFaceOutput.height / 2);
        }

        ParentScope.winPromptWebcamMethod = {
            ToggleWebcam: () => {
                if (isWebcamOn) turnOffCamera();
                else turnOnCamera();
                isWebcamOn = !isWebcamOn;
                ParentScope.winPromptWebcamProp.LabelBtnToggleWebcam = isWebcamOn ? "Turn Off Webcam" : "Turn On Webcam";
                if (isWebcamOn) displayWordInCanvaOfWebcam("Loading...");
            },
            ConfirmCaptureFace: () => {
                if (!isWebcamOn) return;
                ParentScope.winPromptWebcamProp.StartCapture = true;
                ParentScope.winPromptWebcamProp.IsFail = false;
                ParentScope.winPromptWebcamProp.IsSuccess = false;
                ParentScope.winPromptWebcamProp.LabelRegister = "Capturing...";
            },
            DeleteCapturedFace: () => {
                delete ParentScope.winPromptWebcamProp.ImgFaceBase64_ClientToServer;
                if (_.isFunction(OnImageDeleted)) OnImageDeleted();
                handleFaceDeletedSuccessful();
            },
            ContinueAfterCaptureFace: async () => {
                $(`#webcam-continue-${ParentScope.strUniqueID}`).text("Loading...").prop("disabled", true);
                $(`#webcam-delete-image-${ParentScope.strUniqueID}`).prop("disabled", true);
                //Here, the profile picture is sent to azure blob and return an URL
                if (ParentScope.winPromptWebcamProp.CaptureType === "PROFILE") {
                    const config = {
                        transformRequest: angular.identity,
                        headers: { "Content-Type": undefined }
                    };
                    const profileBlob = await (await fetch(ParentScope.winPromptWebcamProp.ImgFaceBase64_ClientToServer)).blob();
                    const attachmentId = broadcastService.generateAttachmentID();
                    const postUrl = `${$rootScope.masterWebApiUrl}AttachNew?AttachmentID=${attachmentId}&FileName=Photo&FileExtension=.jpg&IsMaster=true`;
                    const formData = new FormData();
                    formData.append("file", profileBlob);
                    const resultUrl = await $http.post(postUrl, formData, config).catch(e => httpExceptionHandlerFunction(e));
                    if (!resultUrl) {
                        $(`#webcam-continue-${ParentScope.strUniqueID}`).text("Continue").prop("disabled", false);
                        $(`#webcam-delete-image-${ParentScope.strUniqueID}`).prop("disabled", false);

                        handleAttachImageToAzureBlobFailure();
                        return;
                    }
                    const urlBlob = resultUrl.data.replace(/"/g, '');
                    if (_.isFunction(OnImageCaptured)) OnImageCaptured({ attachmentId, urlBlob });
                }
                else {
                    if (_.isFunction(OnImageCaptured)) OnImageCaptured(ParentScope.winPromptWebcamProp.ImgFaceBase64_ClientToServer);
                }

                ParentScope.winPromptWebcam.close();
            }
        };

        function httpExceptionHandlerFunction({ data, status, headers, config }) {
            const error = errorHandlerService.apiException(data, status, headers, config).replace(/<\/?[^>]+(>|$)/g, "");
            ParentScope.winPromptWebcamProp.IsFail = true;
            ParentScope.winPromptWebcamProp.Message = `${error}`;
            ParentScope.winPromptWebcamProp.LabelRegister = "Capture";
        }

        const turnOnCamera = () => {
            if (videoWebcam.srcObject) return;

            if (enableJSFaceDetect) turnOnCameraWithJsFaceDetection();
            else turnOnCameraWithoutJsFaceDetection();

            function turnOnCameraWithJsFaceDetection() {
                cameraMediapipe = new CameraJS(videoWebcam, getJsFaceDetection());
                cameraMediapipe.start();

                function getJsFaceDetection() {
                    const faceDetection = new FaceDetectionJS(onResultsFace);
                    return faceDetection;

                    async function onResultsFace(results) {
                        if (!enableJSFaceDetect) return; //strange behavior when re-init. Need this to avoid executed if not enable JS face detection

                        const { width, height } = canvaOfWebcamFaceOutput;
                        canvaContextOfWebcamFaceOutput.clearRect(0, 0, width, height);
                        canvaContextOfWebcamFaceOutput.drawImage(results.image, 0, 0, width, height);

                        const hasFace = Boolean(results.detections.length);
                        handleCaptureFace_withJsFaceDetection(hasFace);

                        //drawing boxes at last, so it doesnt impact subsequent cut
                        if (!hasFace) return;
                        const firstFaceBoundingBox = results.detections[0].boundingBox;
                        const firstFaceBoxColor = { color: 'blue', lineWidth: 1, fillColor: '#00000000' };
                        drawRectangle(canvaContextOfWebcamFaceOutput, firstFaceBoundingBox, firstFaceBoxColor);
                    }
                }
            }

            function turnOnCameraWithoutJsFaceDetection() {
                cameraMediapipe = (new CameraJSNoFaceDet(videoWebcam, OnFrame, outputWidth));
                cameraMediapipe.start();

                async function OnFrame() {
                    if (enableJSFaceDetect) return; //strange behavior when re-init. Need this to avoid executed if not enable JS face detection

                    const { width, height } = canvaOfWebcamFaceOutput;
                    canvaContextOfWebcamFaceOutput.clearRect(0, 0, width, height);
                    canvaContextOfWebcamFaceOutput.drawImage(videoWebcam, 0, 0, width, height);

                    await handleCaptureFace_withoutJsFaceDetection();
                }
            }
        }

        const handleCaptureFace_withJsFaceDetection = async (hasFace) => {
            if (!hasFace) {
                if (!ParentScope.winPromptWebcamProp.StartCapture) return;
                handleIfNoFaceDetected();
                ParentScope.winPromptWebcamProp.StartCapture = false;
                return;
            }

            await handleAfterCaptureFace();
        }

        const handleCaptureFace_withoutJsFaceDetection = async () => {
            await handleAfterCaptureFace();
        }

        const captureForProfileImage = async () => {
            if (!ParentScope.winPromptWebcamProp.StartCapture) return;
            ParentScope.winPromptWebcamProp.StartCapture = false;

            const tempCanvas = document.createElement("canvas");
            const tempCanvasCtx = tempCanvas.getContext("2d");
            tempCanvas.width = outputWidth;
            tempCanvas.height = VIDEO_OUTPUT_HEIGHT;
            const sx = 0;
            const sy = 0;
            const sw = outputWidth;
            const sh = VIDEO_OUTPUT_HEIGHT;
            const dx = 0;
            const dy = 0;
            const dw = outputWidth;
            const dh = VIDEO_OUTPUT_HEIGHT;
            tempCanvasCtx.drawImage(canvaOfWebcamFaceOutput, sx, sy, sw, sh, dx, dy, dw, dh);
            
            const ImgFinal = tempCanvas.toDataURL("image/jpeg", 1);
            handleFaceCaptureSuccessful(ImgFinal);
        }

        const uploadCanvaOfWebcamFaceOutput = async () => {
            if (!ParentScope.winPromptWebcamProp.StartCapture) return;
            ParentScope.winPromptWebcamProp.StartCapture = false;
            const ImgBase64Raw = canvaOfWebcamFaceOutput.toDataURL("image/jpeg", 1);
            const ImgBase64 = ImgBase64Raw.split(",").pop();
            const ImgCroppedFaceBase64 = await handleFaceDetectionByAzureFaceService(ImgBase64);
            if (!ImgCroppedFaceBase64) return;
            const ImgCroppedFaceBase64ForTag = this.getImageSourceForTag(ImgCroppedFaceBase64);
            handleFaceCaptureSuccessful(ImgCroppedFaceBase64ForTag);
        }

        const handleFaceDetectionByAzureFaceService = async (ImgBase64) => {
            const postData = { ImgBase64, AttachmentID: broadcastService.generateAttachmentID(), FileExtension: ".jpeg" };
            const postUrl = $rootScope.masterWebApiUrl + "EmpyPerMasPost/GetDetectedFace";
            const ImgCroppedFaceBase64Result = await $http.post(postUrl, postData).catch(e => httpExceptionHandlerFunction(e));
            if (!ImgCroppedFaceBase64Result) return;
            return ImgCroppedFaceBase64Result.data.replace(/['"]+/g, '');
        }

        const handleIfNoFaceDetected = () => {
            ParentScope.$apply(() => {
                ParentScope.winPromptWebcamProp.IsFail = true;
                ParentScope.winPromptWebcamProp.Message = "No face detected.";
                ParentScope.winPromptWebcamProp.LabelRegister = "Capture";
            });
        }

        const handleAttachImageToAzureBlobFailure = () => {
            ParentScope.$apply(() => {
                ParentScope.winPromptWebcamProp.IsFail = true;
                ParentScope.winPromptWebcamProp.IsSuccess = false;
                ParentScope.winPromptWebcamProp.Message = "Attach image error. Please try again later.";
            });
        }

        const handleFaceCaptureSuccessful = (ImgFaceBase64_ClientToServer) => {
            ParentScope.$apply(() => {
                ParentScope.winPromptWebcamProp.IsSuccess = true;
                ParentScope.winPromptWebcamProp.Message = "Face captured.";
                ParentScope.winPromptWebcamProp.LabelRegister = "Capture";
                ParentScope.winPromptWebcamProp.StartCapture = false;
                ParentScope.winPromptWebcamProp.ImgFaceBase64_ClientToServer = ImgFaceBase64_ClientToServer;
                ParentScope.winPromptWebcamMethod.ToggleWebcam();
            });
        }

        const handleFaceDeletedSuccessful = () => {
            ParentScope.winPromptWebcamProp.IsSuccess = true;
            ParentScope.winPromptWebcamProp.Message = "Face deleted.";
            ParentScope.winPromptWebcamProp.LabelRegister = "Capture";
            const { width, height } = canvaOfWebcamFaceOutput;
            canvaContextOfWebcamFaceOutput.clearRect(0, 0, width, height);
            displayWordInCanvaOfWebcam("Webcam");
        }

        const turnOffCamera = () => {
            if (!videoWebcam?.srcObject) return;
            const stream = videoWebcam.srcObject;
            stream.getTracks().forEach(a => a.stop());
            videoWebcam.srcObject = null;
            const { width, height } = canvaOfWebcamFaceOutput;
            canvaContextOfWebcamFaceOutput.clearRect(0, 0, width, height);
            displayWordInCanvaOfWebcam("Webcam");
        }

        //Start Here
        await lazyLoadMediapipes(enableJSFaceDetection);
        await handleWebcamStatus({
            onCameraPermissionDenied: () => {
                alert(MSG_CAMERA_PERMISSION_REQUIRED);
                ParentScope.winPromptWebcamProp.WebcamStatusOk = false;
            },
            onWebcamNotDetected: () => {
                alert(MSG_NO_WEBCAM_DETECTED);
                ParentScope.winPromptWebcamProp.WebcamStatusOk = false;
            },
            onWebcamDetected: () => {
                ParentScope.$apply(() => { ParentScope.winPromptWebcamProp.WebcamStatusOk = true; });
            }
        });
        initWebcamElements();
    }

    const getImageSourceForTag = (_imgBase64) => {
        if (!_imgBase64) return "";
        const prefixForTag = "data:image/jpeg;base64,";
        return _imgBase64.includes(prefixForTag) ? _imgBase64 : `${prefixForTag}${_imgBase64}`;
    }

    const getHasFaceEnrolled = async (EmpyKey) => {
        const urlGet = $rootScope.masterWebApiUrl + `EmpyPerMasPost/GetHasFaceRegistered?EmpyKey=${EmpyKey}`;
        return $http.get(urlGet);
    }

    const identifyFace = async ({ ImgFaceBase64, OUKey }) => {
        const urlPost = $rootScope.masterWebApiUrl + `EmpyPerMasPost/IdentifyFace`;
        const probeImgData = { ImgFaceBase64, OUKey };
        return $http.post(urlPost, probeImgData);
    }

    //ensure that permission is granted, else the label is empty: https://developer.mozilla.org/en-US/docs/Web/API/MediaDeviceInfo/label
    //one mentions that, it doesnt work on local dev machine: https://stackoverflow.com/questions/60297972/navigator-mediadevices-enumeratedevices-returns-empty-labels
    //currently handle 3 types:
    //1. granted, has camera
    //2. granted, no camera
    //3. denied
    //toggle camera permission for testing: chrome://settings/content/camera
    const handleWebcamStatus = async ({ onCameraPermissionDenied, onWebcamNotDetected, onWebcamDetected }) => {
        const LABEL_SCREEN_CAPTURE_RECORDER = "screen-capture-recorder";
        const KIND_VIDEO_INPUT = "videoinput";
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
            const tracks = stream.getTracks();
            tracks[0].stop();
        } 
        catch (err) {
            if (_.isFunction(onCameraPermissionDenied)) onCameraPermissionDenied();
            return false;
        }

        const devices = await navigator.mediaDevices.enumerateDevices();
        const hasCamera = devices.some(a => a.kind === KIND_VIDEO_INPUT && a.label !== LABEL_SCREEN_CAPTURE_RECORDER);
        if (!hasCamera) {
            if (_.isFunction(onWebcamNotDetected)) onWebcamNotDetected();
            return false;
        }
        if (_.isFunction(onWebcamDetected)) onWebcamDetected();
        return true;
    }

    async function lazyLoadMediapipes(_enableJSFaceDetect) {
        const urlCameraUtil = `${DIRECTORY_SCRIPT}camera_utils.js`;
        const urlDrawingUtil = `${DIRECTORY_SCRIPT}drawing_utils.js`;
        const urlFaceDetection = `${DIRECTORY_SCRIPT}face_detection.js`;
        await $.when($ocLazyLoad.load(urlCameraUtil), $ocLazyLoad.load(urlDrawingUtil));
        enableJSFaceDetect = Boolean(_enableJSFaceDetect);
        if (enableJSFaceDetect)
            await $.when($ocLazyLoad.load(urlFaceDetection));
    }

    return {
        lazyLoadMediapipes,
        displayCustomMessageWithWebcam,
        getImageSourceForTag,
        getHasFaceEnrolled,
        identifyFace,
        handleWebcamStatus
    };
});